import DottedMapWithoutCountries, {
    type DottedMapWithoutCountriesLib,
} from 'dotted-map/without-countries';
import { useEffect, useState } from 'react';
import mapJson from '~/assets/map.json';
import type { LocationStat } from '~/hooks/useStats';

interface LocationsProps {
    data: LocationStat[];
    me: LocationStat;
}

function normalizeCount(pointCount: number): number {
    const a = 1;
    const b = 100000;
    const c = 0.4;
    const d = 2.0;
    const normalizedValue = c + ((d - c) * (pointCount - a)) / (b - a);
    return normalizedValue;
}

const bumbleColor = '#FFCB37';
const tinderColor = '#fd5564';

export function StatsMap({ data, me }: LocationsProps) {
    const [svgMapString, setSvgMapString] = useState<string>('');

    useEffect(() => {
        const map = new DottedMapWithoutCountries({
            map: mapJson as any,
        });

        const pins = data.map(
            (point): DottedMapWithoutCountriesLib.Pin => ({
                lat: parseFloat(point.latitude),
                lng: parseFloat(point.longitude),
                svgOptions: {
                    color: point.app === 'bumble' ? bumbleColor : tinderColor,
                    radius: normalizeCount(parseFloat(point.count)),
                },
            }),
        );

        pins.push({
            lat: parseFloat(me.latitude),
            lng: parseFloat(me.longitude),
            svgOptions: {
                color: 'whitesmoke',
                radius: 0.7,
            },
        });

        pins.forEach((point) => map.addPin(point));

        const svg = map.getSVG({
            shape: 'circle',
            color: 'gray',
            radius: 0.25,
        });

        setSvgMapString(svg);
    }, [data, me]);

    return (
        <div className="overflow-hidden">
            <div
                className="-mx-16 map-fade-mask"
                dangerouslySetInnerHTML={{ __html: svgMapString }}
            />
            <p className="container mx-auto md:max-w-md flex gap-2 items-center">
                <span className="w-3 h-3 rounded-full bg-[whitesmoke]" /> you
                are here
            </p>
        </div>
    );
}
