import { StatsMap } from './StatsMap'; // Assuming this component is already migrated
import { useStats } from '~/hooks/useStats';
import { Headline } from '~/components/General/Headline';
import { ClientOnly } from '~/components/ClientOnly';

export function Statistics() {
    const { isLoaded, totalCities, studioStats, usageStats } = useStats();

    const formatNumber = (number: number = 0) => {
        const formatter = new Intl.NumberFormat('en', { notation: 'compact' });
        return formatter.format(number);
    };

    if (!isLoaded) {
        return (
            <section className="container py-12">
                <p className="opacity-25 h-[60vh]">
                    <em>Loading statistics...</em>
                </p>
            </section>
        );
    }

    return (
        <>
            <section className="container py-12">
                <Headline title="Dozens are" titleColored="already using it">
                    Our users are spread all over the world. The map below shows
                    activities in the last week. The color of the dots
                    represents the app used. Updates regularly.
                </Headline>

                {/* Stats boxes */}
                <dl className="flex max-w-screen-xl gap-8 p-4 text-white sm:p-8 justify-evenly flex-wrap">
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold">
                            {totalCities}
                        </dt>
                        <dd className="text-gray-400">Cities</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold">
                            {formatNumber(studioStats?.total_convos)}
                        </dt>
                        <dd className="text-gray-400">Chats</dd>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <dt className="mb-2 text-3xl font-extrabold">
                            {formatNumber(
                                studioStats?.tinder_messages +
                                    studioStats?.bumble_messages,
                            )}
                        </dt>
                        <dd className="text-gray-400">Messages</dd>
                    </div>
                </dl>
            </section>
            <ClientOnly
                children={() => usageStats && <StatsMap {...usageStats} />}
            />
        </>
    );
}
